import "./App.css";
import { Test } from "./components/Test";
import { useState } from "react";
function App() {
  const INIT_ARRAY = [];
  const [enteredName, setEnteredName] = useState(INIT_ARRAY);
  var addName = (name) => {
    setEnteredName([...enteredName, name]);
    console.log(...enteredName, name);
  };
  //deletes the element from the array without direct array mutation
  const deleteElement = (id) => {
    setEnteredName(enteredName.filter((name) => name.id !== id));
  };
  return (
    <>
      <div className="App">
        <center>
          <h1>CRUD</h1>
          <Test onRender={addName} />
          <h5 align="center">Added:</h5>
          {enteredName.map((name) => (
            <div key={name.id}>
              <h5>{name.name}</h5>
              <button onClick={() => deleteElement(name.id)}>Delete</button>
            </div>
          ))}
        </center>
      </div>
    </>
  );
}
export default App;
