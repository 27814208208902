import { React, useState } from "react";
export const Test = (props) => {
  const [enteredName, setEnteredName] = useState(" ");
  const nameHandler = (event) => {
    setEnteredName(event.target.value);
  };
  const submitHandler = (event) => {
    const data = {
      name: enteredName,
      id: Math.floor(Math.random() * 100),
    };
    props.onRender(data); //lifts state
    event.preventDefault();
  };
  return (
    <>
      <div>
        <form onSubmit={submitHandler}>
          <label>Name:</label>
          <input type="text" name="name" onChange={nameHandler} />
          <button>Submit</button>
        </form>
      </div>
    </>
  );
};
